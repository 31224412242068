.rh-mp {
  $complex-transition: 800ms linear;
  $standard-transition: 400ms linear;

  --bg-color: #000;
  --poster-aspect-ratio-lg: 16 / 9;
  --poster-aspect-ratio-md: 16 / 9;
  --poster-aspect-ratio-sm: 16 / 9;
  --poster-aspect-ratio-xl: 16 / 9;
  --poster-aspect-ratio-xs: 16 / 9;
  --source-aspect-ratio: 16 / 9;
  --thumbnail-offset: 0;
  --thumbnail-width: 0;

  @mixin view-state {
    transition: opacity $complex-transition;
    visibility: visible;

    &[data-view-state="hidden"] {
      opacity: 0;
    }

    &[data-view-state="showing"] {
      opacity: 1;
    }

    &[data-view-state="visible"] {
      opacity: 1;
    }

    &[data-view-state="hiding"] {
      opacity: 0;
    }

    &[data-view-state="hidden"] {
      visibility: hidden;
    }

    @content;
  }

  @mixin svg_button($width-ratio: 1, $scale-ratio: 1) {
    & svg {
      height: auto;
      transform: scale(1.2 * $scale-ratio);
      width: 100% * $width-ratio !important;

      &:active:hover {
        transform: scale(1.4 * $scale-ratio);
      }

      @include down_sm {
        transform: scale(1 * $scale-ratio);

        &:active:hover {
          transform: scale(1.2 * $scale-ratio);
        }
      }
    }
  }

  @mixin xs {
    @media (min-width: 0) and (max-width: 768px - 0.05) {
      @content;
    }
  }

  @mixin down_sm {
    @media (max-width: 992px - 0.05) {
      @content;
    }
  }

  @mixin sm {
    @media (min-width: 768px) and (max-width: 992px - 0.05) {
      @content;
    }
  }

  @mixin md {
    @media (min-width: 992px) and (max-width: 1200px - 0.05) {
      @content;
    }
  }

  @mixin lg {
    @media (min-width: 1200px) and (max-width: 1600px - 0.05) {
      @content;
    }
  }

  @mixin xl {
    @media (min-width: 1600px) {
      @content;
    }
  }

  &.rh-mp__root {
    background-color: var(--bg-color);
    font-size: 0;
    height: auto;
    overflow: hidden;
    position: relative;
    width: stretch;
    width: -moz-available;
    width: -webkit-fill-available;
  }

  &.rh-mp-playing.rh-mp-fs.rh-mp-controls-hidden {
    & .rh-mp__root {
      cursor: none;
    }
  }

  & .rh-mp__media__wrapper {
    align-items: center;
    display: flex;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: stretch;
    width: -moz-available;
    width: -webkit-fill-available;
  }

  & .rh-mp__loader {
    color: #fff;
    height: 60px !important;
    width: 60px !important;
  }

  & .rh-mp__loader__wrapper {
    @include view-state;

    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  & .rh-mp__poster {
    object-fit: cover;

    @include xs {
      aspect-ratio: var(--poster-aspect-ratio-xs);
    }

    @include sm {
      aspect-ratio: var(--poster-aspect-ratio-sm);
    }

    @include md {
      aspect-ratio: var(--poster-aspect-ratio-md);
    }

    @include lg {
      aspect-ratio: var(--poster-aspect-ratio-lg);
    }

    @include xl {
      aspect-ratio: var(--poster-aspect-ratio-xl);
    }
  }

  & .rh-mp__poster__wrapper {
    @include view-state;

    height: auto;
    overflow: hidden;
    position: absolute;
    width: stretch;
    width: -moz-available;
    width: -webkit-fill-available;
  }

  &.rh-mp-fs {
    & .rh-mp__root {
      bottom: 0;
      height: stretch;
      height: -moz-available;
      height: -webkit-fill-available;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: auto;
    }

    & .rh-mp__media__wrapper {
      height: stretch;
      height: -moz-available;
      height: -webkit-fill-available;
    }
  }

  &.rh-mp-playing {
    & .rh-mp__controls {
      & .rh-mp__controls__hero__root {
        opacity: 0;
      }
    }
  }

  & .rh-mp__controls {
    &.rh-mp__controls-embedded {
      bottom: 0;
      display: flex;
      left: 0;
      position: absolute;
      flex-direction: column;
      right: 0;
      top: 0;
    }

    & .rh-mp__controls__bar__root {
      @include view-state;

      align-items: center;
      background-color: rgba(0, 0, 0, 0.85);
      bottom: 0;
      display: flex;
      flex-direction: row;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;

      & > * {
        margin-right: 0;

        &:last-child {
          margin-right: 0;
        }
      }

      & * {
        color: #fff;
      }
    }

    & .rh-mp__controls__fullscreen {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      position: relative;

      @include svg_button();
    }

    & .rh-mp__controls__hero__root {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 100%;
      justify-content: center;
      opacity: 1;
      transition: opacity $standard-transition;
      width: 100%;

      & .MuiSvgIcon-root {
        color: #fff;
        height: 25%;
        width: 25%;
      }
    }

    & .rh-mp__controls__play {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      position: relative;

      @include svg_button($width_ratio: 0.5);
    }

    & .rh-mp__controls__progress__bar {
      border-radius: 1px;
      flex-grow: 1px;
      height: 100%;

      &:hover {
        border-radius: 1px;
      }

      & .MuiLinearProgress-dashed {
        animation: none !important;
        background-color: #fff;
        background-image: none !important;
      }

      & .MuiLinearProgress-bar1buffer {
        background-color: #aaa;
      }

      & .MuiLinearProgress-bar2buffer {
        background-color: #ccc;
      }
    }

    & .rh-mp__controls__progress__bar__wrapper {
      height: 5px;
      width: 100%;
    }

    &:hover {
      & .rh-mp__controls__progress__bar__wrapper {
        height: 5px;
        width: 100%;
      }
    }

    & .rh-mp__controls__progress__overlay {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      /*
        This z-index is required because mui is also using a z-index at: https://github.com/mui-org/material-ui/blob/e7bf469195f13bd0bf357111f3e50ac461d2ed38/packages/material-ui/src/linearprogress/linearprogress.js#l99
        We need this to capture the click before it reaches the mui component.
        */
      z-index: 2;
    }

    & .rh-mp__controls__progress__root {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      margin-left: 15px;
      position: relative;
    }

    & .rh-mp__controls__root {
      height: 100%;
      position: absolute;
      width: 100%;
    }

    & .rh-mp__controls__thumbnail {
      aspect-ratio: var(--source-aspect-ratio);
      height: auto;
      max-width: var(--thumbnail-width);
      object-fit: cover;
      width: stretch;
      width: -moz-available;
      width: -webkit-fill-available;
    }

    & .rh-mp__controls__thumbnail__wrapper {
      @include view-state {
        transform: scale(1);

        &[data-view-state^="hid"] {
          transform: scale(0);
        }
      }

      background-color: #000;
      border-color: #fff;
      border-style: solid;
      border-width: 1px;
      bottom: 100%;
      height: fit-content;
      left: calc(var(--thumbnail-offset) * 100%);
      overflow: hidden;
      position: absolute;
      transform-origin: center bottom;
      transition: #{"opacity 250ms ease 0s, transform 500ms cubic-bezier(0, 0.8, 0, 1) 0s"};
      width: var(--thumbnail-width);
    }

    & .rh-mp__controls__timedisplay {
      font-family: "MediaPlayerTimeDisplay";
      font-size: 16.9px;
      line-height: 16.9px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: 33px;

      @include sm {
        font-size: 13px;
        line-height: 13px;
      }
    }

    & .rh-mp__controls__volume {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      margin-left: 9px;
      position: relative;

      @include svg_button($width_ratio: 1.2);
    }

    & .rh-mp__controls__volume__slider {
      margin: 10px;
      min-height: 100px;

      & .MuiSlider-thumb {
        transition: transform 200ms ease 0s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    & .rh-mp__controls__volume__slider__wrapper {
      @include view-state;

      background-color: rgba(0, 0, 0, 0.85);
      border-radius: 0;
      bottom: 100%;
      left: 50%;
      overflow: visible;
      position: absolute;
      transform: translate3d(-50%, 0, 0);
    }

    & .rh-mp__playerbutton__root {
      height: 51px;
      padding: 0;
      transition: transform 200ms ease 0s;
      width: 51px;

      @include down_sm {
        height: 40px;
        width: 40px;
      }
    }

    & .rh-mp__playerbutton__injectedroot {
      align-items: center;
      display: inline-flex;
      fill: #fff;
      flex-direction: row;
      font-size: 0;
      justify-content: center;
    }
  }

  & .rh-mp__video__root {
    @include view-state;

    aspect-ratio: var(--source-aspect-ratio);
    background-color: var(--bg-color);
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  // Custom controls css here SITES-1719
  & .rh-mp__custom-controls {
    display: flex;
    flex-direction: revert;
    position: absolute;
    bottom: 30px;
    right: 30px;
    @include xs {
      width: 100%;
      justify-content: space-between;
      padding: 16px;
      top: 0;
      bottom: auto;
      right: auto;

      .rh-mp__controls__volume__slider__wrapper {
        bottom: auto;
        top: 100%;
      }
    }

    .rh-mp__playerbutton__root {
      width: 40px;
      height: 40px;
    }

    .rh-mp__controls__play svg {
      color: #fff;
    }
  }
}
